/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './src/**/*.{jsx,js,css}'
  ],
  theme: {
    colors: {
      transparent: 'transparent',
      primary: {
        DEFAULT: '#34B878',
        light: '#86E1B5',
        dark: '#2A9969',
        200: '#D6EDE0',
        100: '#F2FBF6'
      },
      secondary: {
        DEFAULT: '#9282E5',
        light: '#AC9DE7',
        dark: '#5E5094',
        200: '#E1DEF2',
        100: '#F5F5FD'
      },
      ternary: {
        light: '#3a3a3a',
        DEFAULT: '#242424',
        dark: '#121212'
      },
      white: {
        DEFAULT: '#ffffff'
      },
      black: '#000000',
      gray: {
        DEFAULT: '#dedede',
        light: '#f8f8f8',
        dark: '#a9a9a9',
        100: '#f8f8f8',
        200: '#e5e7eb',
        500: '#e1def2',
        600: '#767676',
        700: '#636363',
        800: '#434343',
        900: '#292929'
      },
      error: {
        DEFAULT: '#E52233',
        light: '#fce9eb',
        dark: '#d61a2a'
      },
      warning: {
        DEFAULT: '#ff9800',
        light: '#fff9bf'
      },
      success: {
        DEFAULT: '#40c62b',
        light: '#d0f0ca',
        'light-2': '#efffeb'
      }
    },
    placeholderColor: {
      black: '#242424'
    },
    fontSize: {
      '2xs': ['9px', '12px'],
      xs: ['12px', '18px'],
      sm: ['13px', '18px'],
      md: ['14px', '18px'],
      lg: ['15px', '18px'],
      xl: ['19px', '30px'],
      '2xl': ['24px', '34px'],
      '3xl': ['40px', '50px'],
      '4xl': ['60px', '70px']
    },
    fontFamily: {
      base: ["'Source Sans 3'", 'sans-serif']
    },
    fontWeight: {
      normal: 400,
      medium: 500,
      bold: 700,
      'bold-2': 800
    },
    container: {
      center: true,
      padding: '1.25rem'
    },
    extend: {
      screens: {
        '3xl': '1750px'
      },
      borderWidth: {
        3: '3px',
        5: '5px'
      },
      borderColor: {
        DEFAULT: '#e1def2'
      },
      minHeight: {
        6: '1.5rem',
        8: '2rem',
        10: '2.5rem',
        11: '2.75rem',
        12: '3rem',
        13: '3.25rem',
        14: '3.5rem',
        48: '12rem',
        vh: '100vh'
      },
      ringWidth: {
        3: '3px'
      },
      padding: {
        4.5: '1.1rem'
      },
      width: {
        4.5: '1.1rem',
        192: '48rem'
      },
      dropShadow: {
        xl: '0px 8px 24px rgba(26, 45, 94, 0.1)',
        '2xl': '0 4px 24px rgba(60, 58, 108, 0.16)',
        sidebar: '0px 0px 24px rgba(27, 46, 94, 0.05)'
      },
      typography: (theme) => ({
        DEFAULT: {
          css: {
            color: theme('colors.primary.DEFAULT'),
            a: {
              color: theme('colors.secondary.DEFAULT'),
              '&:hover': {
                color: theme('colors.secondary.DEFAULT'),
                textDecoration: 'none'
              }
            },
            ul: {
              paddingLeft: 0
            },
            'ul > li': {
              listStyleType: 'none',
              marginTop: 0,
              marginBottom: '0.25rem',
              paddingLeft: '1.75rem',
              backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 10 7\' width=\'10\' height=\'7\'%3E%3Crect fill=\'%23e52233\' x=\'1\' width=\'8\' height=\'0.5\' /%3E%3Crect fill=\'%23e52233\' x=\'1\' y=\'6\' width=\'8\' height=\'0.5\' /%3E%3Crect fill=\'%23e52233\' y=\'3.9\' width=\'10\' height=\'0.5\' /%3E%3Crect fill=\'%23e52233\' y=\'1.9\' width=\'10\' height=\'0.5\' /%3E%3C/svg%3E")',
              backgroundPosition: 'left',
              backgroundRepeat: 'no-repeat'
            },
            h2: {
              fontWeight: 400,
              marginBottom: '0.5rem'
            }
          }
        },
        white: {
          css: {
            color: theme('colors.white.DEFAULT'),
            a: {
              color: theme('colors.white.DEFAULT'),
              '&:hover': {
                color: theme('colors.white.DEFAULT'),
                textDecoration: 'none'
              }
            },
            ul: {
              marginTop: 0
            },
            strong: {
              color: theme('colors.white.DEFAULT')
            }
          }
        }
      })
    }
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography')
  ]
}
